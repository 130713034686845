import { getConfig } from '@sydle/ui-utils';
import type { UIApiConfig, UserAPI } from '.';
import { defineAPIs, getAPI } from '.';
import { CURRENT_LANGUAGE } from './lib/constants';

defineAPIs();

const defaultLanguage = getConfig<UIApiConfig>('ui-api')?.DEFAULT_LANGUAGE;
const isLangDefined = !!(sessionStorage.getItem(CURRENT_LANGUAGE) || localStorage.getItem(CURRENT_LANGUAGE));
if (!isLangDefined && defaultLanguage) {
  const userAPI = getAPI<UserAPI>('userAPI');
  userAPI?.setCurrentLanguage(defaultLanguage);
}
